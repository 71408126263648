// ========================== Settings Panel ==========================

.settings-panel {
  border: 1px dotted #8b8fbb;
  border-radius: 90px;
  padding: 13px 20px;
  max-height: 107px;
  box-sizing: border-box;
}

.header + .settings-panel {
  margin-top: 14px;
}

.settings-panel + .total-amounts {
  margin-top: 14px;
}

.settings-panel-title {
  position: relative;
  padding: 10px 3px 3px;
  color: #b1b3d9;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 17px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #a7aad1 transparent transparent transparent;
  }

  &.active:after {
    transform: rotateX(180deg);
  }
}

.settings-panel-current {
  padding-left: 3px;
  padding-right: 3px;

  div {
    display: inline-block;
    padding: 1px 8px;
    margin-right: 3px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 40px;
    background-color: #6c6e9f;
  }
}

.settings-panel-fields {
  display: flex;
  align-items: center;
}

.settings-panel-field {
  padding: 0 20px;
  max-width: 260px;
  width: 20%;
  text-align: left;
  box-sizing: border-box;

  &.date-time {
    box-sizing: border-box;

    img {
      margin-left: -24px;
    }
  }

  &.border-left {
    border-left: 1px dotted #8b8fbb;
  }
}

.settings-panel-credit {
  flex: 1;
  padding-left: 20px;
  text-align: left;
  box-sizing: border-box;
}

.settings-panel-label {
  color: #b1b3d9;
  font-size: 16px;
  margin-bottom: 5px;

  img {
    width: 19px;
    height: 19px;

    & + span {
      margin-left: 6px;
    }
  }

  @media (max-width: 1399px) {
    font-size: 14px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  @media (max-width: 1199px) {
    font-size: 12px;
  }

  @media (max-width: 349px) {
    font-size: 11px;

    img {
      width: 12px;
      height: 12px;
    }
  }
}

.credit-settings {
  display: flex;
  align-items: center;

  .inline-button-container + .inline-button-container {
    margin-left: 0.521vw; // 10px
  }
}

.credit-value {
  margin-right: 1.979vw; // 38px

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  .label {
    font-size: 0.9375vw; // 18px
    font-weight: 700;
    line-height: 1.333;
    color: #a7aad1;
  }

  .value {
    font-size: 1.09375vw; // 24px
    font-weight: 700;
    line-height: 1.571;
    color: #fdc66b;
  }
}

// Mobile

.settings-panel.mobile {
  position: relative;
  max-height: none;
  padding: 2px 12px 14px;
  border-radius: 20px;
  text-align: left;

  @media (max-width: 369px) {
    padding-left: 7px;
    padding-right: 7px;
  }

  .settings-panel-fields {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .settings-panel-field {
    width: 25%;
    padding: 0 5px 0 15px;
    margin-top: 10px;
    max-width: none;

    &.date-time {
      width: 50%;
    }

    @media (max-width: 799px) {
      width: 50%;
    }

    @media (max-width: 579px) {
      width: 100%;
    }
  }

  .settings-panel-label {
    img {
      margin-left: -15px;

      & + span {
        margin-left: 3px;
      }
    }

    * {
      vertical-align: middle;
    }
  }

  .settings-panel-content {
    max-width: 280px;

    @media (max-width: 579px) {
      max-width: none;
    }
  }

  .rdtPicker {
    left: -20px;
  }

  @media (max-width: 519px) {
    .rdt {
      position: static;
    }

    .rdtPicker {
      top: -2px;
      left: -1px;
      right: -1px;
      width: auto;
    }
  }

  @media (min-width: 370px) and (max-width: 519px) {
    .rdtDays {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;

      table {
        border-spacing: 15px 0;
      }
    }
  }

  @media (max-width: 349px) {
    .rdt input {
      font-size: 14px;
    }
  }

  .credit-settings {
    margin-top: 15px;

    .button.featured {
      position: relative;
      width: 80px;
      height: 54px;
      padding: 0;
      font-size: 0;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #69d7b3;
      }

      &:before {
        width: 20px;
        height: 4px;
      }

      &:after {
        width: 4px;
        height: 20px;
      }

      &.alt {
        &:before {
          width: 12px;
          height: 5px;
          background-color: #f57fc2;
        }

        &:after {
          content: none;
        }
      }
    }

    .inline-button-container + .inline-button-container {
      margin-left: 8px;
    }
  }

  .credit-value {
    margin-right: 36px;

    @media (max-width: 399px) {
      margin-right: auto;
    }

    .label {
      color: #b1b3d9;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 1px;

      img {
        margin-right: 3px;
        margin-left: -18px;
      }
    }

    .value {
      font-size: 16px;
    }
  }
}
