// ========================== Header ==========================

.header {
  position: relative;
  height: 30vh;
  max-height: 172px;

  .labels {
    width: 100%;
    height: 60%;
    display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: flex-start;
  }

  .label-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: 32px;
    }

    &.load_funds {
      border-top-right-radius: 20px;
      transition: all 200ms ease;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.unload_funds {
      border-top-left-radius: 20px;
      transition: all 200ms ease;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &.active {
      transition: all 200ms ease;
      background: $font_color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .label-symbol {
      color: $font_color;
      font-size: 13em;
      position: absolute;
      line-height: 0;
      z-index: 1;
      top: 66%;
      right: 20px;

      &.unload_funds {
        top: 41%;
      }
    }

    .label {
      width: 100px;
      z-index: 2;
      display: flex;
      padding-left: 60px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      font-size: 2.4vh;
      font-weight: bold;

      &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 5rem;
        top: 18px;
        left: 40px;
      }

      &.load_funds {
        span {
          color: $action_color_green;
        }

        &::before {
          border: 4px solid $action_color_green;
        }
      }

      &.unload_funds {
        span {
          color: $action_color_pink;
        }

        &::before {
          border: 4px solid $action_color_pink;
        }
      }
    }

    &:first-child {
      &::after {
        content: '';
        height: 100%;
        width: 1px;
        position: absolute;
        right: 0;
        border-left: 1px dotted rgba(0, 0, 0, 0.15);
      }
    }
  }

  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $input_bg;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;

    span {
      color: $hint_color;
    }

    .credit-value {
      color: $action_color_green;
    }
  }

  .information-user {
    cursor: pointer;
    display: flex;
    padding: 0 20px;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    box-sizing: border-box;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    width: 100%;
    position: relative;
    margin-right: 0;
    transition: all 200ms ease;

    img {
      min-width: 20px;
      min-height: 20px;
      margin-right: 10px;
    }
  }

  .information-user-account {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    line-height: 40px;
    padding-left: 20px;
    border-left: 1px dotted #5f638b;

    .user-name {
      margin-right: 3px;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .icon-arrow {
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top: 3px solid $hint_color;
      border-right: 3px solid $hint_color;

      &.down {
        transition: all 300ms ease;
        margin: 3px 6px 6px;
        transform: rotate(135deg);
      }

      &.up {
        transition: all 300ms ease;
        margin: 12px 6px 6px;
        transform: rotate(-45deg);
      }
    }
  }

  .header-content-top {
    display: flex;
    height: 70px;
    min-height: 70px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 2vh;
    z-index: 2;
    background-color: $bg_color;
    border-radius: 25px;
  }

  .header-content-usermenu {
    padding-right: 5px;
    width: 45px;
    height: 45px;
    position: relative;

    .user-status {
      position: absolute;
      width: 8px;
      height: 8px;
      right: 5px;
      top: 8%;
      border: 2px solid $input_active_bg;
      border-radius: 5rem;

      &.online {
        background: $action_color_green;
      }

      &.offline {
        background: $action_color_pink;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .header-content-game {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    .header-select {
      width: 90%;
      max-width: 300px;
      text-align: left;
    }

    .status {
      min-width: 15px;
      min-height: 15px;
      margin: 0 15px 0 0;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      border-radius: 50%;

      &.on {
        background: $action_color_green;
      }

      &.off {
        background: $action_color_pink;
      }
    }

    @media (max-width: 1366px) {
      width: 60%;
    }
  }

  .header-content-logo-game {
    width: 33%;
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    align-items: center;
  }

  .header-content-balance {
    display: flex;
    align-items: center;
    background-color: $input_bg;
    padding: 5px 12px;
    border-radius: 5rem;

    img {
      height: 40px;
      width: 34px;
    }

    .balance-value {
      margin: 0 13px;
      font-size: 3vh;
      font-weight: bold;
      color: #fcbe69;
    }
  }

  .header-content-information {
    display: flex;
    height: 100%;
    width: 33%;
    z-index: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.information-credit {
  display: flex;
  height: 100%;
  margin: 0 20px;
  align-items: flex-start;
  font-size: 16px;
  line-height: 22px;
  flex-direction: row;
  justify-content: flex-start;

  &.column {
    flex-direction: column;
    justify-content: center;
    line-height: normal;
  }

  .credit-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;

    .highlighted {
      color: #fff;
    }
  }

  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  .credit-value {
    color: $action_color_green;

    &.credit-value--alt {
      color: #fdc66b;
    }
  }
}

// admin styles

.app-container.admin {
  .header {
    height: auto;
    max-height: none;
  }

  .header-content-logo-game {
    width: 67%;
  }
}

// Header Tabs

.header-tabs {
  position: relative;
  display: flex;
  align-items: stretch;
  background: linear-gradient($input_bg 30%, $font_color 45%, $font_color 100%);
  margin-top: 12px;
  font-size: 13px;

  .header-tabs-substrate {
    width: 100%;
    flex: 0 1 100%;
    background-color: $input_bg;
    border-bottom-right-radius: 35px;
  }

  .header-tab.active + .header-tabs-substrate {
    border-bottom-left-radius: 20px;
  }

  .enable-credit {
    position: absolute;
    right: 32px;
    top: 40%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    color: #a7aad1;
    font-size: 1rem;
    width: 180px;

    & > * + * {
      margin-left: 15px;
    }

    .enable-credit-label {
      min-width: 6.354vw;
      text-align: right;
    }

    .enable-credit-toggle > div {
      display: block !important;
    }
  }
}

.header-tab {
  padding: 1.406vw 3.125vw;
  background-color: $input_bg;
  flex: 1 0 auto;

  &.active {
    background-color: $font_color;
  }

  &.active:first-child {
    border-top-right-radius: 20px;
  }

  &:nth-child(2) {
    border-bottom-left-radius: 20px;
  }

  &:first-child:not(.active) {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 20px;
  }

  &.active:nth-child(2) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.header-tab-label {
  font-size: 26px;
  line-height: 30px;

  span {
    text-transform: capitalize;
  }

  & > * {
    vertical-align: middle;
  }
}

.header-tab-icon {
  margin-right: 8px;
}

// Header Mobile

.header.mobile {
  height: auto;
  max-height: none;

  .logo-container {
    margin-left: 5px;
  }

  .labels {
    padding-top: 10px;
    height: 100%;
    align-items: flex-end;
  }

  .label-container {
    height: 90%;
    padding: 5px;

    &:not(.active) {
      background: $input_bg;
    }

    .label {
      padding-left: 40px;

      &::before {
        left: 20px;
      }
    }

    &:first-child {
      &::after {
        border: none;
      }
    }
  }

  .header-container {
    justify-content: space-between;
    background: linear-gradient($input_bg 78%, $font_color 85%, $font_color 100%);

    .enable-credit {
      display: flex;
      align-items: center;
      color: #a7aad1;
      font-size: 14px;
    }

    .enable-credit-toggle {
      line-height: 0;
      margin-right: 5px;
    }

    @media (max-width: 479px) {
      .information-credit {
        span {
          font-size: 11px;
        }

        .credit-value {
          font-size: 18px;
        }
      }

      .label-container {
        .label {
          &::before {
            width: 10px;
            height: 10px;
            top: 9px;
            left: 25px;
          }
        }

        h4 {
          font-size: 20px;
        }
      }

      .enable-credit {
        font-size: 12px;
      }
    }
  }

  .header-content-top {
    padding: 0 5px;
  }

  .header-content-balance {
    img {
      height: 34px;
      width: 28px;
    }
  }

  .header-content-logo-game {
    width: auto;
  }

  .header-tabs {
    margin-top: 5px;
  }

  .header-tab {
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 14px;

    &:first-child {
      border-bottom-left-radius: 20px;
    }

    &:nth-child(2) {
      border-bottom-right-radius: 20px;
    }

    &.active:nth-child(2) {
      border-top-right-radius: 0;
    }
  }

  .header-tab-label {
    font-size: 20px;

    @media (max-width: 479px) {
      font-size: 16px;
    }
  }

  .header-tab-icon {
    height: auto;
    margin-right: 5px;

    &.history {
      width: 18px;
    }

    &.credit {
      width: 23px;
    }
  }
}
