// ========================== User Menu ==========================

.user-menu-top-right {
  position: absolute;
  top: 0;
  right: -1px;
  z-index: 5;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #4b4f74, #5f6389);
  box-shadow: 0 5px 15px 0 rgba(39, 41, 74, 0.7);
  display: flex;
  overflow: hidden;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding: 0 20px;
  width: calc(100% + 2px);
  max-width: 200px;
  flex-direction: column;
  justify-content: flex-start;

  .icon-arrow {
    transform: rotate(315deg) !important;
    margin: 12px 6px 6px !important;
    transition: transform 300ms ease;
  }

  div,
  a {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: #9da0c7;
      text-decoration-width: 2px;
    }
  }

  .information-user-logout {
    display: flex;
    flex-direction: row;
    border-top: 1px dotted #27294a;
    justify-content: flex-start;
    align-items: center;
  }
}

.user-top-menu-enter {
  transition: all 300ms ease;
  height: 100%;
  padding-top: 22px;
  opacity: 1;

  .icon-arrow {
    transform: rotate(135deg) !important;
    margin: 3px 6px 6px !important;
    transition: transform 300ms ease;
  }
}

.user-top-menu-enter-active {
  transition: all 300ms ease;
  opacity: 1;
  top: 0;
  padding-top: 0;
  height: auto;

  .icon-arrow {
    transform: rotate(315deg) !important;
    margin: 12px 6px 6px !important;
    transition: transform 300ms ease;
  }
}

.user-top-menu-exit-active {
  transition: all 400ms ease;
  height: 100%;
  opacity: 0;
  padding-top: 22px;

  .information-user-logout {
    display: none;
  }

  .icon-arrow {
    transform: rotate(135deg) !important;
    margin: 3px 6px 6px !important;
    transition: transform 300ms ease;
  }
}

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px dotted $input_active_bg;
  font-size: 1rem;
  padding: 15px 0;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin: 0 15px 0 5px;
  }

  .credit {
    color: $action_color_pink;
    font-size: 2.6vh;
    font-weight: bold;
  }

  .name {
    width: 70%;
    text-align: left;
    color: #fff;
    overflow: hidden;
  }

  .status {
    min-width: 15px;
    min-height: 15px;
    margin: 0 15px 0 10px;
    -webkit-border-radius: 5rem;
    -moz-border-radius: 5rem;
    border-radius: 50%;

    &.on {
      background: $action_color_green;
    }

    &.off {
      background: $action_color_pink;
    }
  }

  .icon-arrow {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    border-top-color: $hint_color;
    border-right-color: $hint_color;

    &.down {
      transition: all 300ms ease;
      margin: 3px 6px 6px;
      transform: rotate(135deg);
    }

    &.up {
      transition: all 300ms ease;
      margin: 12px 6px 6px;
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 479px) {
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
      margin: 0 15px 0 5px;
    }

    .status {
      margin: 0 15px 0 5px;
    }
  }
}

// Mobile User Menu

.user-menu-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: auto;
  display: flex;
  padding: 6px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;

  .user-menu-body {
    width: 95%;
    padding-bottom: 6px;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(to top, #484c70, #65698f);
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 25px;
    box-shadow: 0 5px 15px 0 rgba(39, 41, 74, 0.7);
    height: 95%;

    .user-menu-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      overflow: hidden;
      padding: 8px;
      font-size: 18px;
      justify-content: space-between;

      .user-menu-name {
        display: flex;
        z-index: 2;
        width: 50%;
        justify-content: flex-start;
        align-items: center;

        .name {
          margin-left: 5px;
          font-size: 16px;
        }
      }

      .user-menu-close {
        z-index: 1;
      }

      img {
        width: 41px;
        height: 41px;
      }
    }

    .user-menu-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;
      padding: 0 13px;

      a {
        text-decoration: none;
      }

      .items-header {
        width: 100%;
        text-align: left;
      }
    }

    .user-menu-select {
      margin: 10px 5px;
    }
  }
}

.user-menu-enter {
  transform: translateY(-600px);

  .user-menu-icon {
    fill: #ced0ea;
  }
}

.user-menu-enter-active {
  transform: translateY(0);
  transition: all 200ms ease, opacity 300ms ease;

  .user-menu-icon {
    transition: all 200ms ease;
    fill: #5f638b;
  }
}

.user-menu-exit {
  opacity: 1;
  width: 100%;
}

.user-menu-exit-active {
  transform: translateY(-600px);
  transition: all 300ms ease;
}
