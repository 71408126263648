// ========================== Input ==========================

.input-container {
  width: 100%;
  height: 51px;
  margin-top: 13px;
  position: relative;

  .input-icon {
    position: absolute;
    height: 20px;
    width: 18px;
    right: 21px;
    top: 13px;
    fill: #5f638b;
    transition: fill 0.4s ease;

    &.active {
      transition: fill 0.4s ease;
      fill: $action_color_green;
    }

    &.error {
      cursor: pointer;
      transition: fill 0.4s ease;
      fill: $action_color_pink;
    }
  }

  .input {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 5rem;
    background: $input_bg;
    color: $white;
    font-size: 18px;
    padding: 10px 42px 10px 20px;
    transition: all 0.4s ease;

    &::placeholder {
      color: #5f638b;
    }

    &:focus {
      transition: all 0.4s ease;
      background: $input_active_bg;
      caret-color: #5f638b;
      color: $white;

      &::placeholder {
        opacity: 0.3;
      }
    }

    &.error {
      transition: all 0.4s ease;
      color: $action_color_pink;
      border: 1px solid $action_color_pink;

      &::placeholder {
        color: $action_color_pink;
      }
    }
  }
}

// Filter Input

.filter-input-container {
  width: 100%;
  margin: 20px 0;
  max-height: 60px;
  position: relative;

  .input-key {
    position: absolute;
    font-size: 2.6vh; // 24px
    line-height: 18px;
    top: 19px;
    left: 20px;

    &.load_funds {
      color: $action_color_green;
    }

    &.unload_funds {
      color: $action_color_pink;
    }
  }

  .filter-input-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 30px;
    top: 19px;
  }

  .filter-input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 55px;
    color: #ffffff;
    line-height: 40px;
    border-radius: 5rem;
    background-color: $input_bg;
    font-size: 1rem;

    &::placeholder {
      color: #5f638b;
    }

    &:focus {
      transition: all 0.4s ease;
      background: $input_active_bg;
      caret-color: #5f638b;
      color: $white;

      &::placeholder {
        opacity: 0.3;
      }
    }
  }

  @media (max-width: 479px) {
    .filter-input {
      font-size: 14px;
      line-height: 30px;
    }

    .filter-input-icon {
      top: 17px;
      right: 22px;
      width: 17px;
      height: 17px;
    }
  }
}

// Admin Filter Input
.app-container.admin {
  .input-key {
    font-size: 16px;
    line-height: 1.67vh;
    top: 50%;
    transform: translate(0, -50%);
    left: 20px;
  }
}

// Mobile

.app-container.mobile {
  .filter-input-container {
    margin-top: 15px;
    margin-bottom: 10px;

    .filter-input {
      padding-left: 25px;
    }
  }
}
