// ========================== LOGIN PAGE ==========================

.content.login {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-around;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  padding: 67px 28px 28px;
  width: 30%;
  min-width: 315px;
  min-height: 350px;
  height: 45%;
  background-image: $modal_gradient;

  .content-logo {
    width: 108px;
    height: 108px;
    background-color: $action_color_pink;
    display: flex;
    position: absolute;
    top: -53px;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;

    img {
      height: 84px;
      width: 64px;
    }
  }

  .content-header {
    width: 100%;
    text-align: center;

    &.error {
      color: $action_color_pink;
    }
  }
}

@media (max-height: 499px) {
  .content.login {
    overflow-y: auto;
    justify-content: flex-start;
    padding-top: 32px;
  }

  .content-login {
    min-height: 300px;

    .content-logo {
      width: 70px;
      height: 70px;
      top: -30px;

      img {
        width: 38px;
        height: auto;
      }
    }
  }
}

// for detect Google Chrome autofill
#login_id:-webkit-autofill {
  animation-name: onAutoFillStart;
}

@keyframes onAutoFillStart {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 0;
  }
}
