// ========================== Reset default styles ==========================

input {
  outline: none;
  border: none;
  font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

h1,
h2,
h3,
h4,
h5,
li,
ul,
p,
span {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

div {
  -webkit-tap-highlight-color: transparent;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}
