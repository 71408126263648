// ========================== Modal ==========================

.modal-enter,
.field-enter {
  opacity: 0;
}

.modal-enter-active,
.field-enter-active {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.modal-exit,
.field-exit {
  opacity: 1;
}

.modal-exit-active,
.field-exit-active {
  opacity: 0;
  transition: all 300ms ease;

  .modal-body {
    .modal-field {
      display: none;
    }
  }
}

.balance-enter {
  opacity: 0;
  transform: translateY(-100px);
}

.balance-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease;
}

.balance-exit {
  opacity: 1;
  transform: translateY(0);
}

.balance-exit-active {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 200ms ease;
}

.modal-container {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-body {
    width: 36%;
    min-width: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    color: $hint_color;
    z-index: 2;
    position: relative;
    height: 400px;
    border-radius: 40px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    transition: height 500ms ease;
    background-image: linear-gradient(to top, #484c70, #65698f);

    &.open {
      height: 550px;
      transition: height 300ms ease;

      &.confirm {
        height: 650px;
        transition: height 300ms ease;
      }

      .phone-field,
      .unit-field {
        transition: margin 300ms ease;
      }
    }

    .modal-button {
      position: absolute;
      width: 100%;
      bottom: 40px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
    }

    .button-container {
      width: 40%;
      margin: 0 5%;
      position: relative;

      .back-button {
        background: transparent;
        color: white;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
        text-decoration: underline;
        font-size: 18px;

        &::before {
          content: '<';
          position: absolute;
          left: -10px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    .modal-body {
      width: 98%;
      height: 98%;
      min-height: 350px;
      min-width: 0;

      .modal-header {
        height: 60px;
        box-sizing: border-box;
      }

      &.open {
        height: 98%;

        .modal-info {
          top: 115px;
        }

        .modal-button {
          transition: all 300ms ease;
          bottom: calc(100% - 490px);

          &.send-code {
            transition: all 300ms ease;
            bottom: calc(100% - 600px);
          }
        }

        .modal-field {
          .value-fild {
            margin-top: 10px;
          }
        }
      }

      .modal-button {
        position: static;
        margin-top: 30px;
        transition: all 300ms ease;

        &.send-code {
          transition: all 300ms ease;
          bottom: calc(100% - 450px);
        }
      }
    }
  }

  @media (max-height: 359px) {
    justify-content: flex-start;
    overflow-y: auto;
  }
}

.overlay {
  z-index: 1;
  position: absolute;
  background: transparent;
  background: $input_bg;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.modal-field {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .code-fild {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input-container {
      &::before {
        display: none;
      }
    }
  }

  .value-fild {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input {
      padding-left: 60px;
    }

    .input-container {
      &::before {
        content: 'R';
        line-height: 50px;
        position: absolute;
        width: 50px;
        color: white;
        background: $input_bg;
        border-radius: 5rem;
        height: 100%;
      }

      &.error {
        &::before {
          content: 'R';
          line-height: 50px;
          position: absolute;
          width: 50px;
          top: 1px;
          left: 1px;
          box-sizing: border-box;
          color: white;
          background: $input_bg;
          border-radius: 5rem;
          height: 96%;
        }
      }
    }
  }

  .phone-field,
  .unit-field,
  .amount-field {
    transition: margin 300ms ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.phone-field .input-container {
  .input {
    padding-left: 60px;
  }

  &::before {
    content: '+27';
    line-height: 50px;
    position: absolute;
    width: 50px;
    color: white;
    background: $input_bg;
    border-radius: 5rem;
    height: 100%;
  }

  &.error {
    &::before {
      content: '+27';
      line-height: 50px;
      position: absolute;
      width: 50px;
      box-sizing: border-box;
      color: $action_color_pink;
      background: $input_bg;
      border-radius: 5rem;
      margin: 1px;
      height: 95%;
    }
  }
}

.modal-header {
  display: flex;
  padding: 10px 20px 0;
  justify-content: space-between;
  align-items: center;

  .close-model {
    width: 25px;
    cursor: pointer;
    height: 25px;
  }

  span {
    border-right: none;
  }
}

.modal-info {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 15px;
  font-size: 16px;
  margin: 10px 0;
  background: lighten($input_active_bg, 3%);

  span {
    font-size: 16px;
  }

  b {
    display: inline-block;
    color: #fff;
    margin: 0 5px;
  }

  .information-credit {
    width: 100%;
    justify-content: space-between;
    margin: 0;

    img {
      margin-right: 20px;
    }
  }

  &.balance {
    color: white;
    top: 20%;
    background: transparent;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}

// Info Modal

.info-modal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .info-modal-body {
    z-index: 10;
    position: relative;
    width: 40%;
    height: 50%;
    max-width: 544px;
    max-height: 430px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    min-height: 400px;
    background-color: $font_color;
    border-radius: 35px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .info-modal-status-image {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      &.success {
        background-color: #52cba2;
      }

      &.error {
        background-color: #e85595;
      }

      img {
        width: 82px;
        height: 64.9px;
        object-fit: contain;
      }
    }

    .info-modal-content {
      display: flex;
      flex: 1;
      padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info-modal-description {
        width: 100%;
        padding: 0 20px;
        font-size: 26px;
        font-weight: bold;

        &.error {
          color: $action_color_pink;
        }

        &.success {
          color: #ffffff;

          span {
            font-size: 32px;
          }
        }

        .green-text {
          color: $action_color_green;
        }

        .red-text {
          color: #e85595;
        }
      }
    }
  }

  .info-modal-container {
    display: flex;
    padding: 10px 20px 0;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  .info-modal-logo {
    width: 70px;
    height: 70px;
    background-color: #e55895;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;

    img {
      width: 43px;
      height: 54px;
    }
  }

  .info-modal-close img {
    width: 38px;
    cursor: pointer;
    height: 38px;
  }

  @media (max-width: 479px) {
    .info-modal-body {
      max-height: 100%;
      max-width: 100%;
      width: calc(100% - 10px);
      height: 100%;
      margin: 10px 0;

      .info-modal-content {
        height: 65%;
        justify-content: center;

        .info-modal-description {
          font-size: 21px;
        }
      }
    }

    .info-modal-logo {
      width: 30px;
      height: 30px;

      img {
        width: 17px;
        height: 23px;
      }
    }

    .info-modal-close img {
      width: 25px;
      height: 25px;
    }
  }
}
