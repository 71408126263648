// ========================== Buttons ==========================

.button-container {
  width: 100%;
  height: 52px;
  margin: 13px 0;
}

.inline-button-container {
  display: inline-block;
}

.button {
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  overflow: hidden;
  background: $action_color_green;
  color: $white;
  position: relative;
  font-size: 18px;
  transition: all 0.4s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 4px $input_bg;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background: transparent;
    width: 2px;
    height: 2px;
    z-index: 3;
    animation: ripple 0.5s linear;
  }

  &.error {
    filter: brightness(70%);
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &.featured {
    background: transparent;
    border: 3px solid #69d7b3;
    color: #69d7b3;
    font-weight: 700;
    padding: 21px 27px;
    height: auto;
    width: auto;

    &.alt {
      border-color: #f57fc2;
      color: #f57fc2;
    }

    &.disabled {
      opacity: 0.3;
    }
  }

  &.dependedOnVW {
    font-size: 0.9375vw; // 18px

    &.featured {
      padding: 1vw 1.40625vw; // 21px 27px
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    background-image: radial-gradient(
      circle closest-side,
      rgba(255, 255, 255, 0.3),
      lighten($font_color, 5%)
    );
    opacity: 0;
  }

  50% {
    transform: scale(100, 100);
    background-image: radial-gradient(
      circle closest-side,
      rgba(255, 255, 255, 0.3),
      lighten($font_color, 5%)
    );
    opacity: 0.75;
  }

  100% {
    transform: scale(200, 200);
    background-image: radial-gradient(
      circle closest-side,
      rgba(255, 255, 255, 0.3),
      lighten($font_color, 5%)
    );
    opacity: 0;
  }
}
