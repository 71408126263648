@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700&subset=cyrillic);
input {
  outline: none;
  border: none;
  font-family: inherit; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

h1,
h2,
h3,
h4,
h5,
li,
ul,
p,
span {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent; }

div {
  -webkit-tap-highlight-color: transparent; }

button {
  outline: none;
  border: none;
  cursor: pointer; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative; }
  .rdt input {
    background-color: #494d71;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    width: 100%; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #2a2c48;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
  border-radius: 15px; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 30px; }

.rdtPicker td {
  cursor: pointer; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  background: #5cc2a0;
  cursor: pointer;
  border-radius: 50%; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #5cc2a0;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default; }

.rdtPicker th.rdtSwitch {
  width: 100px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #5cc2a0; }

.rdtCounter .rdtCount {
  height: 24px;
  font-size: 21px;
  line-height: 24px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtTime td {
  cursor: default; }

.content.login {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.content-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-around;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  padding: 67px 28px 28px;
  width: 30%;
  min-width: 315px;
  min-height: 350px;
  height: 45%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#484c70), to(#65698f));
  background-image: linear-gradient(to top, #484c70, #65698f); }
  .content-login .content-logo {
    width: 108px;
    height: 108px;
    background-color: #e55895;
    display: flex;
    position: absolute;
    top: -53px;
    justify-content: center;
    align-items: center;
    border-radius: 5rem; }
    .content-login .content-logo img {
      height: 84px;
      width: 64px; }
  .content-login .content-header {
    width: 100%;
    text-align: center; }
    .content-login .content-header.error {
      color: #e55895; }

@media (max-height: 499px) {
  .content.login {
    overflow-y: auto;
    justify-content: flex-start;
    padding-top: 32px; }
  .content-login {
    min-height: 300px; }
    .content-login .content-logo {
      width: 70px;
      height: 70px;
      top: -30px; }
      .content-login .content-logo img {
        width: 38px;
        height: auto; } }

#login_id:-webkit-autofill {
  -webkit-animation-name: onAutoFillStart;
          animation-name: onAutoFillStart; }

@-webkit-keyframes onAutoFillStart {
  from {
    margin-left: 0; }
  to {
    margin-left: 0; } }

@keyframes onAutoFillStart {
  from {
    margin-left: 0; }
  to {
    margin-left: 0; } }

.button-container {
  width: 100%;
  height: 52px;
  margin: 13px 0; }

.inline-button-container {
  display: inline-block; }

.button {
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  overflow: hidden;
  background: #58caa3;
  color: #ffffff;
  position: relative;
  font-size: 18px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .button:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    box-shadow: 0 6px 4px #323558; }
  .button .circle {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background: transparent;
    width: 2px;
    height: 2px;
    z-index: 3;
    -webkit-animation: ripple 0.5s linear;
            animation: ripple 0.5s linear; }
  .button.error {
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    cursor: not-allowed;
    opacity: 0.6; }
    .button.error:hover {
      -webkit-transform: none;
              transform: none;
      box-shadow: none; }
  .button.featured {
    background: transparent;
    border: 3px solid #69d7b3;
    color: #69d7b3;
    font-weight: 700;
    padding: 21px 27px;
    height: auto;
    width: auto; }
    .button.featured.alt {
      border-color: #f57fc2;
      color: #f57fc2; }
    .button.featured.disabled {
      opacity: 0.3; }
  .button.dependedOnVW {
    font-size: 0.9375vw; }
    .button.dependedOnVW.featured {
      padding: 1vw 1.40625vw; }

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0; }
  50% {
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0.75; }
  100% {
    -webkit-transform: scale(200, 200);
            transform: scale(200, 200);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0; } }

@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0; }
  50% {
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0.75; }
  100% {
    -webkit-transform: scale(200, 200);
            transform: scale(200, 200);
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.3), #535880);
    opacity: 0; } }

.input-container {
  width: 100%;
  height: 51px;
  margin-top: 13px;
  position: relative; }
  .input-container .input-icon {
    position: absolute;
    height: 20px;
    width: 18px;
    right: 21px;
    top: 13px;
    fill: #5f638b;
    -webkit-transition: fill 0.4s ease;
    transition: fill 0.4s ease; }
    .input-container .input-icon.active {
      -webkit-transition: fill 0.4s ease;
      transition: fill 0.4s ease;
      fill: #58caa3; }
    .input-container .input-icon.error {
      cursor: pointer;
      -webkit-transition: fill 0.4s ease;
      transition: fill 0.4s ease;
      fill: #e55895; }
  .input-container .input {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 5rem;
    background: #323558;
    color: #ffffff;
    font-size: 18px;
    padding: 10px 42px 10px 20px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .input-container .input::-webkit-input-placeholder {
      color: #5f638b; }
    .input-container .input::-moz-placeholder {
      color: #5f638b; }
    .input-container .input:-ms-input-placeholder {
      color: #5f638b; }
    .input-container .input::-ms-input-placeholder {
      color: #5f638b; }
    .input-container .input::placeholder {
      color: #5f638b; }
    .input-container .input:focus {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: #27294a;
      caret-color: #5f638b;
      color: #ffffff; }
      .input-container .input:focus::-webkit-input-placeholder {
        opacity: 0.3; }
      .input-container .input:focus::-moz-placeholder {
        opacity: 0.3; }
      .input-container .input:focus:-ms-input-placeholder {
        opacity: 0.3; }
      .input-container .input:focus::-ms-input-placeholder {
        opacity: 0.3; }
      .input-container .input:focus::placeholder {
        opacity: 0.3; }
    .input-container .input.error {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      color: #e55895;
      border: 1px solid #e55895; }
      .input-container .input.error::-webkit-input-placeholder {
        color: #e55895; }
      .input-container .input.error::-moz-placeholder {
        color: #e55895; }
      .input-container .input.error:-ms-input-placeholder {
        color: #e55895; }
      .input-container .input.error::-ms-input-placeholder {
        color: #e55895; }
      .input-container .input.error::placeholder {
        color: #e55895; }

.filter-input-container {
  width: 100%;
  margin: 20px 0;
  max-height: 60px;
  position: relative; }
  .filter-input-container .input-key {
    position: absolute;
    font-size: 2.6vh;
    line-height: 18px;
    top: 19px;
    left: 20px; }
    .filter-input-container .input-key.load_funds {
      color: #58caa3; }
    .filter-input-container .input-key.unload_funds {
      color: #e55895; }
  .filter-input-container .filter-input-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 30px;
    top: 19px; }
  .filter-input-container .filter-input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 55px;
    color: #ffffff;
    line-height: 40px;
    border-radius: 5rem;
    background-color: #323558;
    font-size: 1rem; }
    .filter-input-container .filter-input::-webkit-input-placeholder {
      color: #5f638b; }
    .filter-input-container .filter-input::-moz-placeholder {
      color: #5f638b; }
    .filter-input-container .filter-input:-ms-input-placeholder {
      color: #5f638b; }
    .filter-input-container .filter-input::-ms-input-placeholder {
      color: #5f638b; }
    .filter-input-container .filter-input::placeholder {
      color: #5f638b; }
    .filter-input-container .filter-input:focus {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: #27294a;
      caret-color: #5f638b;
      color: #ffffff; }
      .filter-input-container .filter-input:focus::-webkit-input-placeholder {
        opacity: 0.3; }
      .filter-input-container .filter-input:focus::-moz-placeholder {
        opacity: 0.3; }
      .filter-input-container .filter-input:focus:-ms-input-placeholder {
        opacity: 0.3; }
      .filter-input-container .filter-input:focus::-ms-input-placeholder {
        opacity: 0.3; }
      .filter-input-container .filter-input:focus::placeholder {
        opacity: 0.3; }
  @media (max-width: 479px) {
    .filter-input-container .filter-input {
      font-size: 14px;
      line-height: 30px; }
    .filter-input-container .filter-input-icon {
      top: 17px;
      right: 22px;
      width: 17px;
      height: 17px; } }

.app-container.admin .input-key {
  font-size: 16px;
  line-height: 1.67vh;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 20px; }

.app-container.mobile .filter-input-container {
  margin-top: 15px;
  margin-bottom: 10px; }
  .app-container.mobile .filter-input-container .filter-input {
    padding-left: 25px; }

.header {
  position: relative;
  height: 30vh;
  max-height: 172px; }
  .header .labels {
    width: 100%;
    height: 60%;
    display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: flex-start; }
  .header .label-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center; }
    .header .label-container h4 {
      font-size: 32px; }
    .header .label-container.load_funds {
      border-top-right-radius: 20px;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px; }
    .header .label-container.unload_funds {
      border-top-left-radius: 20px;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; }
    .header .label-container.active {
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      background: #494d71;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .header .label-container .label-symbol {
      color: #494d71;
      font-size: 13em;
      position: absolute;
      line-height: 0;
      z-index: 1;
      top: 66%;
      right: 20px; }
      .header .label-container .label-symbol.unload_funds {
        top: 41%; }
    .header .label-container .label {
      width: 100px;
      z-index: 2;
      display: flex;
      padding-left: 60px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      font-size: 2.4vh;
      font-weight: bold; }
      .header .label-container .label::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 5rem;
        top: 18px;
        left: 40px; }
      .header .label-container .label.load_funds span {
        color: #58caa3; }
      .header .label-container .label.load_funds::before {
        border: 4px solid #58caa3; }
      .header .label-container .label.unload_funds span {
        color: #e55895; }
      .header .label-container .label.unload_funds::before {
        border: 4px solid #e55895; }
    .header .label-container:first-child::after {
      content: '';
      height: 100%;
      width: 1px;
      position: absolute;
      right: 0;
      border-left: 1px dotted rgba(0, 0, 0, 0.15); }
  .header .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #323558;
    border-radius: 25px; }
    .header .header-container span {
      color: #9da0c7; }
    .header .header-container .credit-value {
      color: #58caa3; }
  .header .information-user {
    cursor: pointer;
    display: flex;
    padding: 0 20px;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    box-sizing: border-box;
    border-radius: 35px;
    width: 100%;
    position: relative;
    margin-right: 0;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    .header .information-user img {
      min-width: 20px;
      min-height: 20px;
      margin-right: 10px; }
  .header .information-user-account {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    line-height: 40px;
    padding-left: 20px;
    border-left: 1px dotted #5f638b; }
    .header .information-user-account .user-name {
      margin-right: 3px; }
    .header .information-user-account img {
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    .header .information-user-account .icon-arrow {
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top: 3px solid #9da0c7;
      border-right: 3px solid #9da0c7; }
      .header .information-user-account .icon-arrow.down {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        margin: 3px 6px 6px;
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg); }
      .header .information-user-account .icon-arrow.up {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        margin: 12px 6px 6px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
  .header .header-content-top {
    display: flex;
    height: 70px;
    min-height: 70px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 2vh;
    z-index: 2;
    background-color: #27294a;
    border-radius: 25px; }
  .header .header-content-usermenu {
    padding-right: 5px;
    width: 45px;
    height: 45px;
    position: relative; }
    .header .header-content-usermenu .user-status {
      position: absolute;
      width: 8px;
      height: 8px;
      right: 5px;
      top: 8%;
      border: 2px solid #27294a;
      border-radius: 5rem; }
      .header .header-content-usermenu .user-status.online {
        background: #58caa3; }
      .header .header-content-usermenu .user-status.offline {
        background: #e55895; }
    .header .header-content-usermenu img {
      width: 100%;
      height: 100%; }
    .header .header-content-usermenu:hover {
      cursor: pointer; }
  .header .header-content-game {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center; }
    .header .header-content-game .header-select {
      width: 90%;
      max-width: 300px;
      text-align: left; }
    .header .header-content-game .status {
      min-width: 15px;
      min-height: 15px;
      margin: 0 15px 0 0;
      border-radius: 50%; }
      .header .header-content-game .status.on {
        background: #58caa3; }
      .header .header-content-game .status.off {
        background: #e55895; }
    @media (max-width: 1366px) {
      .header .header-content-game {
        width: 60%; } }
  .header .header-content-logo-game {
    width: 33%;
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    align-items: center; }
  .header .header-content-balance {
    display: flex;
    align-items: center;
    background-color: #323558;
    padding: 5px 12px;
    border-radius: 5rem; }
    .header .header-content-balance img {
      height: 40px;
      width: 34px; }
    .header .header-content-balance .balance-value {
      margin: 0 13px;
      font-size: 3vh;
      font-weight: bold;
      color: #fcbe69; }
  .header .header-content-information {
    display: flex;
    height: 100%;
    width: 33%;
    z-index: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start; }

.information-credit {
  display: flex;
  height: 100%;
  margin: 0 20px;
  align-items: flex-start;
  font-size: 16px;
  line-height: 22px;
  flex-direction: row;
  justify-content: flex-start; }
  .information-credit.column {
    flex-direction: column;
    justify-content: center;
    line-height: normal; }
  .information-credit .credit-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left; }
    .information-credit .credit-label .highlighted {
      color: #fff; }
  .information-credit img {
    width: 22px;
    height: 22px;
    margin-right: 10px; }
  .information-credit .credit-value {
    color: #58caa3; }
    .information-credit .credit-value.credit-value--alt {
      color: #fdc66b; }

.app-container.admin .header {
  height: auto;
  max-height: none; }

.app-container.admin .header-content-logo-game {
  width: 67%; }

.header-tabs {
  position: relative;
  display: flex;
  align-items: stretch;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #323558), color-stop(45%, #494d71), to(#494d71));
  background: linear-gradient(#323558 30%, #494d71 45%, #494d71 100%);
  margin-top: 12px;
  font-size: 13px; }
  .header-tabs .header-tabs-substrate {
    width: 100%;
    flex: 0 1 100%;
    background-color: #323558;
    border-bottom-right-radius: 35px; }
  .header-tabs .header-tab.active + .header-tabs-substrate {
    border-bottom-left-radius: 20px; }
  .header-tabs .enable-credit {
    position: absolute;
    right: 32px;
    top: 40%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    display: flex;
    align-items: center;
    color: #a7aad1;
    font-size: 1rem;
    width: 180px; }
    .header-tabs .enable-credit > * + * {
      margin-left: 15px; }
    .header-tabs .enable-credit .enable-credit-label {
      min-width: 6.354vw;
      text-align: right; }
    .header-tabs .enable-credit .enable-credit-toggle > div {
      display: block !important; }

.header-tab {
  padding: 1.406vw 3.125vw;
  background-color: #323558;
  flex: 1 0 auto; }
  .header-tab.active {
    background-color: #494d71; }
  .header-tab.active:first-child {
    border-top-right-radius: 20px; }
  .header-tab:nth-child(2) {
    border-bottom-left-radius: 20px; }
  .header-tab:first-child:not(.active) {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 20px; }
  .header-tab.active:nth-child(2) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }

.header-tab-label {
  font-size: 26px;
  line-height: 30px; }
  .header-tab-label span {
    text-transform: capitalize; }
  .header-tab-label > * {
    vertical-align: middle; }

.header-tab-icon {
  margin-right: 8px; }

.header.mobile {
  height: auto;
  max-height: none; }
  .header.mobile .logo-container {
    margin-left: 5px; }
  .header.mobile .labels {
    padding-top: 10px;
    height: 100%;
    align-items: flex-end; }
  .header.mobile .label-container {
    height: 90%;
    padding: 5px; }
    .header.mobile .label-container:not(.active) {
      background: #323558; }
    .header.mobile .label-container .label {
      padding-left: 40px; }
      .header.mobile .label-container .label::before {
        left: 20px; }
    .header.mobile .label-container:first-child::after {
      border: none; }
  .header.mobile .header-container {
    justify-content: space-between;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(78%, #323558), color-stop(85%, #494d71), to(#494d71));
    background: linear-gradient(#323558 78%, #494d71 85%, #494d71 100%); }
    .header.mobile .header-container .enable-credit {
      display: flex;
      align-items: center;
      color: #a7aad1;
      font-size: 14px; }
    .header.mobile .header-container .enable-credit-toggle {
      line-height: 0;
      margin-right: 5px; }
    @media (max-width: 479px) {
      .header.mobile .header-container .information-credit span {
        font-size: 11px; }
      .header.mobile .header-container .information-credit .credit-value {
        font-size: 18px; }
      .header.mobile .header-container .label-container .label::before {
        width: 10px;
        height: 10px;
        top: 9px;
        left: 25px; }
      .header.mobile .header-container .label-container h4 {
        font-size: 20px; }
      .header.mobile .header-container .enable-credit {
        font-size: 12px; } }
  .header.mobile .header-content-top {
    padding: 0 5px; }
  .header.mobile .header-content-balance img {
    height: 34px;
    width: 28px; }
  .header.mobile .header-content-logo-game {
    width: auto; }
  .header.mobile .header-tabs {
    margin-top: 5px; }
  .header.mobile .header-tab {
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 14px; }
    .header.mobile .header-tab:first-child {
      border-bottom-left-radius: 20px; }
    .header.mobile .header-tab:nth-child(2) {
      border-bottom-right-radius: 20px; }
    .header.mobile .header-tab.active:nth-child(2) {
      border-top-right-radius: 0; }
  .header.mobile .header-tab-label {
    font-size: 20px; }
    @media (max-width: 479px) {
      .header.mobile .header-tab-label {
        font-size: 16px; } }
  .header.mobile .header-tab-icon {
    height: auto;
    margin-right: 5px; }
    .header.mobile .header-tab-icon.history {
      width: 18px; }
    .header.mobile .header-tab-icon.credit {
      width: 23px; }

.menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 100%;
  max-width: 275px;
  max-height: 275px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 25px;
  justify-content: flex-start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: #34375c; }
  .menu-button.load_funds, .menu-button.unload_funds {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
    .menu-button.load_funds.disabled, .menu-button.unload_funds.disabled {
      -webkit-filter: brightness(70%);
              filter: brightness(70%);
      cursor: inherit; }
      .menu-button.load_funds.disabled .menu-button-key,
      .menu-button.load_funds.disabled .menu-button-name,
      .menu-button.load_funds.disabled .button-icon, .menu-button.unload_funds.disabled .menu-button-key,
      .menu-button.unload_funds.disabled .menu-button-name,
      .menu-button.unload_funds.disabled .button-icon {
        opacity: 0.4; }
      .menu-button.load_funds.disabled:active, .menu-button.unload_funds.disabled:active {
        box-shadow: none;
        outline: inherit;
        border: inherit; }
        .menu-button.load_funds.disabled:active .menu-button-key, .menu-button.unload_funds.disabled:active .menu-button-key {
          color: #34375c;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.3); }
        .menu-button.load_funds.disabled:active .menu-button-name, .menu-button.unload_funds.disabled:active .menu-button-name {
          color: #ffffff; }
        .menu-button.load_funds.disabled:active .button-icon, .menu-button.unload_funds.disabled:active .button-icon {
          fill: #34375c; }
    .menu-button.load_funds:active, .menu-button.unload_funds:active {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      box-shadow: 0 5px 25px 0 #27294a;
      outline: none !important;
      background-color: #323558; }
      .menu-button.load_funds:active .menu-button-key, .menu-button.unload_funds:active .menu-button-key {
        border-bottom: none;
        color: #494d71; }
      .menu-button.load_funds:active .button-icon, .menu-button.unload_funds:active .button-icon {
        width: 60px;
        cursor: pointer;
        height: 70px; }
  .menu-button.load_funds {
    background-color: #58caa3; }
    .menu-button.load_funds.disabled:active {
      background-color: #58caa3; }
    .menu-button.load_funds:active {
      border: 2px solid #58caa3;
      margin: -2px; }
      .menu-button.load_funds:active .button-icon {
        fill: #58caa3; }
  .menu-button.unload_funds {
    background-color: #e55895; }
    .menu-button.unload_funds.disabled:active {
      background-color: #e55895; }
    .menu-button.unload_funds:active {
      border: 2px solid #e55895;
      margin: -2px; }
      .menu-button.unload_funds:active .button-icon {
        fill: #e55895; }
  .menu-button .menu-button-key {
    width: 100%;
    font-size: 3vh;
    line-height: 140%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3); }
  .menu-button .menu-button-name {
    color: #ffffff;
    font-size: 3vh;
    margin-bottom: 14px; }
  .menu-button .button-icon {
    margin: 6px 0 10px 0;
    width: 60px;
    height: 70px;
    fill: #34375c; }

.app-container.mobile .menu-button {
  justify-content: space-evenly; }
  .app-container.mobile .menu-button .menu-button-key {
    display: none; }
  .app-container.mobile .menu-button .button-icon {
    min-width: 35px;
    min-height: 40px;
    margin-top: 25px; }
  @media (max-width: 479px) {
    .app-container.mobile .menu-button {
      height: 110px;
      width: 31%; }
      .app-container.mobile .menu-button .button-icon {
        width: 50px;
        height: 50px;
        margin-top: 17px; } }

.app-container.mobile .menu-button-name {
  font-size: 16px; }
  @media (max-width: 479px) {
    .app-container.mobile .menu-button-name {
      font-size: 14px; } }

.table-history-sections {
  display: flex; }
  .table-history-sections tbody {
    width: 100%; }
  .table-history-sections tr {
    display: flex; }
  .table-history-sections td {
    width: 100%; }

.history-sections {
  width: 100%;
  overflow-y: scroll;
  flex: 1 1;
  height: 0; }
  .history-sections::-webkit-scrollbar {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: #494d71; }
  .history-sections::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 4px;
    background-color: #323558; }

.history-container {
  padding-right: 10px; }
  @media (max-width: 399px) {
    .history-container {
      padding-right: 0; } }

.history-date {
  width: 24%;
  max-width: 90px;
  min-width: 55px;
  text-align: left; }

.history-time {
  width: 13%;
  max-width: 50px;
  text-align: left; }

.history-type {
  width: 8%; }
  .history-type .button-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle; }
    @media (max-width: 399px) {
      .history-type .button-icon {
        width: 10px;
        height: 10px; } }
    .history-type .button-icon.load_funds {
      fill: #58caa3; }
    .history-type .button-icon.unload_funds {
      fill: #e55895; }

.history-title {
  color: #2c2e55;
  border-bottom: 1px solid #27294a;
  margin-bottom: 10px;
  padding: 5px 20px;
  display: flex; }

.history-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 10px;
  padding: 5px 20px; }
  .history-item:nth-child(even) {
    background-color: rgba(50, 53, 88, 0.5);
    border-radius: 5rem; }

.history-desc {
  width: 35%;
  text-align: left;
  margin-left: 4%; }

.history-value {
  width: 26%;
  min-width: 65px;
  text-align: right; }
  .history-value.load_funds {
    color: #58caa3; }
  .history-value.unload_funds {
    color: #e55895; }
  .history-value.inc_cashier_credit {
    color: #58caa3; }
  .history-value.dec_cashier_credit {
    color: #e55895; }

.history-value-credit {
  color: #fdc66b;
  min-width: 74px; }

.history-alt {
  width: 100%;
  height: 45vh;
  position: relative;
  margin-top: 20px; }
  .history-alt .history-container {
    height: 100%; }
    .history-alt .history-container .history-item {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
      padding: 0.26vw 1.04vw;
      font-size: 1.25vw;
      line-height: 2.6vw; }
      .history-alt .history-container .history-item:nth-child(even) {
        background-color: rgba(50, 53, 88, 0.5);
        border-radius: 5rem; }
      .history-alt .history-container .history-item .history-date {
        text-align: left;
        flex: 0 0 11ex; }
      .history-alt .history-container .history-item .history-time {
        text-align: left;
        flex: 0 0 5ex; }
      .history-alt .history-container .history-item .history-type {
        flex: 0 1 200px; }
        .history-alt .history-container .history-item .history-type .button-icon {
          width: 28px;
          height: 28px;
          padding: 0 62px 0 110px; }
          .history-alt .history-container .history-item .history-type .button-icon.load_funds {
            fill: #58caa3; }
          .history-alt .history-container .history-item .history-type .button-icon.unload_funds {
            fill: #e55895; }
      .history-alt .history-container .history-item .history-desc {
        text-align: left;
        flex: 1 1 12ex; }
      .history-alt .history-container .history-item .history-value {
        flex: 1 1 8ex;
        text-align: right; }
        .history-alt .history-container .history-item .history-value.load_funds {
          color: #58caa3; }
        .history-alt .history-container .history-item .history-value.unload_funds {
          color: #e55895; }
      .history-alt .history-container .history-item .history-clBallance {
        flex: 1 1 6ex;
        text-align: right;
        color: #fdc66b; }
  .history-alt .history-sections::-webkit-scrollbar {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: #494d71; }
  .history-alt .history-sections::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 4px;
    background-color: #323558; }
  .history-alt.history-credits .history-container .history-item .history-clBallance {
    flex: 0 0 18ex; }

.app-container.mobile .history-title {
  font-size: 12px; }

.app-container.mobile .history-sections {
  min-height: 33vh;
  max-height: 50vh; }
  @media (max-height: 399px) and (min-aspect-ratio: 13 / 9) {
    .app-container.mobile .history-sections {
      max-height: 75vh; } }
  .app-container.mobile .history-sections .history-item {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px; }
    @media (max-width: 479px) {
      .app-container.mobile .history-sections .history-item {
        padding: 7px 8px; } }
    @media (max-width: 549px) {
      .app-container.mobile .history-sections .history-item {
        font-size: 10px; } }

.modal-enter,
.field-enter {
  opacity: 0; }

.modal-enter-active,
.field-enter-active {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.modal-exit,
.field-exit {
  opacity: 1; }

.modal-exit-active,
.field-exit-active {
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }
  .modal-exit-active .modal-body .modal-field,
  .field-exit-active .modal-body .modal-field {
    display: none; }

.balance-enter {
  opacity: 0;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px); }

.balance-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.balance-exit {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.balance-exit-active {
  opacity: 0;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.modal-container {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .modal-container .modal-body {
    width: 36%;
    min-width: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    color: #9da0c7;
    z-index: 2;
    position: relative;
    height: 400px;
    border-radius: 40px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: height 500ms ease;
    transition: height 500ms ease;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#484c70), to(#65698f));
    background-image: linear-gradient(to top, #484c70, #65698f); }
    .modal-container .modal-body.open {
      height: 550px;
      -webkit-transition: height 300ms ease;
      transition: height 300ms ease; }
      .modal-container .modal-body.open.confirm {
        height: 650px;
        -webkit-transition: height 300ms ease;
        transition: height 300ms ease; }
      .modal-container .modal-body.open .phone-field,
      .modal-container .modal-body.open .unit-field {
        -webkit-transition: margin 300ms ease;
        transition: margin 300ms ease; }
    .modal-container .modal-body .modal-button {
      position: absolute;
      width: 100%;
      bottom: 40px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px; }
    .modal-container .modal-body .button-container {
      width: 40%;
      margin: 0 5%;
      position: relative; }
      .modal-container .modal-body .button-container .back-button {
        background: transparent;
        color: white;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
        text-decoration: underline;
        font-size: 18px; }
        .modal-container .modal-body .button-container .back-button::before {
          content: '<';
          position: absolute;
          left: -10px; }
  @media (max-width: 599px) {
    .modal-container .modal-body {
      width: 98%;
      height: 98%;
      min-height: 350px;
      min-width: 0; }
      .modal-container .modal-body .modal-header {
        height: 60px;
        box-sizing: border-box; }
      .modal-container .modal-body.open {
        height: 98%; }
        .modal-container .modal-body.open .modal-info {
          top: 115px; }
        .modal-container .modal-body.open .modal-button {
          -webkit-transition: all 300ms ease;
          transition: all 300ms ease;
          bottom: calc(100% - 490px); }
          .modal-container .modal-body.open .modal-button.send-code {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            bottom: calc(100% - 600px); }
        .modal-container .modal-body.open .modal-field .value-fild {
          margin-top: 10px; }
      .modal-container .modal-body .modal-button {
        position: static;
        margin-top: 30px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease; }
        .modal-container .modal-body .modal-button.send-code {
          -webkit-transition: all 300ms ease;
          transition: all 300ms ease;
          bottom: calc(100% - 450px); } }
  @media (max-height: 359px) {
    .modal-container {
      justify-content: flex-start;
      overflow-y: auto; } }

.overlay {
  z-index: 1;
  position: absolute;
  background: transparent;
  background: #323558;
  opacity: 0.7;
  width: 100%;
  height: 100%; }

.modal-field {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .modal-field .code-fild {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .modal-field .code-fild .input-container::before {
      display: none; }
  .modal-field .value-fild {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .modal-field .value-fild .input {
      padding-left: 60px; }
    .modal-field .value-fild .input-container::before {
      content: 'R';
      line-height: 50px;
      position: absolute;
      width: 50px;
      color: white;
      background: #323558;
      border-radius: 5rem;
      height: 100%; }
    .modal-field .value-fild .input-container.error::before {
      content: 'R';
      line-height: 50px;
      position: absolute;
      width: 50px;
      top: 1px;
      left: 1px;
      box-sizing: border-box;
      color: white;
      background: #323558;
      border-radius: 5rem;
      height: 96%; }
  .modal-field .phone-field,
  .modal-field .unit-field,
  .modal-field .amount-field {
    -webkit-transition: margin 300ms ease;
    transition: margin 300ms ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

.phone-field .input-container .input {
  padding-left: 60px; }

.phone-field .input-container::before {
  content: '+27';
  line-height: 50px;
  position: absolute;
  width: 50px;
  color: white;
  background: #323558;
  border-radius: 5rem;
  height: 100%; }

.phone-field .input-container.error::before {
  content: '+27';
  line-height: 50px;
  position: absolute;
  width: 50px;
  box-sizing: border-box;
  color: #e55895;
  background: #323558;
  border-radius: 5rem;
  margin: 1px;
  height: 95%; }

.modal-header {
  display: flex;
  padding: 10px 20px 0;
  justify-content: space-between;
  align-items: center; }
  .modal-header .close-model {
    width: 25px;
    cursor: pointer;
    height: 25px; }
  .modal-header span {
    border-right: none; }

.modal-info {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 15px;
  font-size: 16px;
  margin: 10px 0;
  background: #2c2f54; }
  .modal-info span {
    font-size: 16px; }
  .modal-info b {
    display: inline-block;
    color: #fff;
    margin: 0 5px; }
  .modal-info .information-credit {
    width: 100%;
    justify-content: space-between;
    margin: 0; }
    .modal-info .information-credit img {
      margin-right: 20px; }
  .modal-info.balance {
    color: white;
    top: 20%;
    background: transparent;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.info-modal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .info-modal .info-modal-body {
    z-index: 10;
    position: relative;
    width: 40%;
    height: 50%;
    max-width: 544px;
    max-height: 430px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    min-height: 400px;
    background-color: #494d71;
    border-radius: 35px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .info-modal .info-modal-body .info-modal-status-image {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px; }
      .info-modal .info-modal-body .info-modal-status-image.success {
        background-color: #52cba2; }
      .info-modal .info-modal-body .info-modal-status-image.error {
        background-color: #e85595; }
      .info-modal .info-modal-body .info-modal-status-image img {
        width: 82px;
        height: 64.9px;
        object-fit: contain; }
    .info-modal .info-modal-body .info-modal-content {
      display: flex;
      flex: 1 1;
      padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .info-modal .info-modal-body .info-modal-content .info-modal-description {
        width: 100%;
        padding: 0 20px;
        font-size: 26px;
        font-weight: bold; }
        .info-modal .info-modal-body .info-modal-content .info-modal-description.error {
          color: #e55895; }
        .info-modal .info-modal-body .info-modal-content .info-modal-description.success {
          color: #ffffff; }
          .info-modal .info-modal-body .info-modal-content .info-modal-description.success span {
            font-size: 32px; }
        .info-modal .info-modal-body .info-modal-content .info-modal-description .green-text {
          color: #58caa3; }
        .info-modal .info-modal-body .info-modal-content .info-modal-description .red-text {
          color: #e85595; }
  .info-modal .info-modal-container {
    display: flex;
    padding: 10px 20px 0;
    justify-content: space-between;
    align-items: center;
    width: 90%; }
  .info-modal .info-modal-logo {
    width: 70px;
    height: 70px;
    background-color: #e55895;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem; }
    .info-modal .info-modal-logo img {
      width: 43px;
      height: 54px; }
  .info-modal .info-modal-close img {
    width: 38px;
    cursor: pointer;
    height: 38px; }
  @media (max-width: 479px) {
    .info-modal .info-modal-body {
      max-height: 100%;
      max-width: 100%;
      width: calc(100% - 10px);
      height: 100%;
      margin: 10px 0; }
      .info-modal .info-modal-body .info-modal-content {
        height: 65%;
        justify-content: center; }
        .info-modal .info-modal-body .info-modal-content .info-modal-description {
          font-size: 21px; }
    .info-modal .info-modal-logo {
      width: 30px;
      height: 30px; }
      .info-modal .info-modal-logo img {
        width: 17px;
        height: 23px; }
    .info-modal .info-modal-close img {
      width: 25px;
      height: 25px; } }

.user-menu-top-right {
  position: absolute;
  top: 0;
  right: -1px;
  z-index: 5;
  box-sizing: border-box;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4b4f74), to(#5f6389));
  background-image: linear-gradient(to bottom, #4b4f74, #5f6389);
  box-shadow: 0 5px 15px 0 rgba(39, 41, 74, 0.7);
  display: flex;
  overflow: hidden;
  border-radius: 25px;
  padding: 0 20px;
  width: calc(100% + 2px);
  max-width: 200px;
  flex-direction: column;
  justify-content: flex-start; }
  .user-menu-top-right .icon-arrow {
    -webkit-transform: rotate(315deg) !important;
            transform: rotate(315deg) !important;
    margin: 12px 6px 6px !important;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }
  .user-menu-top-right div,
  .user-menu-top-right a {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none; }
    .user-menu-top-right div:hover,
    .user-menu-top-right a:hover {
      text-decoration: underline;
      -webkit-text-decoration-style: solid;
              text-decoration-style: solid;
      -webkit-text-decoration-color: #9da0c7;
              text-decoration-color: #9da0c7;
      text-decoration-width: 2px; }
  .user-menu-top-right .information-user-logout {
    display: flex;
    flex-direction: row;
    border-top: 1px dotted #27294a;
    justify-content: flex-start;
    align-items: center; }

.user-top-menu-enter {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  height: 100%;
  padding-top: 22px;
  opacity: 1; }
  .user-top-menu-enter .icon-arrow {
    -webkit-transform: rotate(135deg) !important;
            transform: rotate(135deg) !important;
    margin: 3px 6px 6px !important;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }

.user-top-menu-enter-active {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 1;
  top: 0;
  padding-top: 0;
  height: auto; }
  .user-top-menu-enter-active .icon-arrow {
    -webkit-transform: rotate(315deg) !important;
            transform: rotate(315deg) !important;
    margin: 12px 6px 6px !important;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }

.user-top-menu-exit-active {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  height: 100%;
  opacity: 0;
  padding-top: 22px; }
  .user-top-menu-exit-active .information-user-logout {
    display: none; }
  .user-top-menu-exit-active .icon-arrow {
    -webkit-transform: rotate(135deg) !important;
            transform: rotate(135deg) !important;
    margin: 3px 6px 6px !important;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px dotted #27294a;
  font-size: 1rem;
  padding: 15px 0; }
  .menu-item:hover {
    cursor: pointer; }
  .menu-item img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin: 0 15px 0 5px; }
  .menu-item .credit {
    color: #e55895;
    font-size: 2.6vh;
    font-weight: bold; }
  .menu-item .name {
    width: 70%;
    text-align: left;
    color: #fff;
    overflow: hidden; }
  .menu-item .status {
    min-width: 15px;
    min-height: 15px;
    margin: 0 15px 0 10px;
    border-radius: 50%; }
    .menu-item .status.on {
      background: #58caa3; }
    .menu-item .status.off {
      background: #e55895; }
  .menu-item .icon-arrow {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    border-top-color: #9da0c7;
    border-right-color: #9da0c7; }
    .menu-item .icon-arrow.down {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      margin: 3px 6px 6px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg); }
    .menu-item .icon-arrow.up {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      margin: 12px 6px 6px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  @media (max-width: 479px) {
    .menu-item {
      font-size: 14px; }
      .menu-item img {
        width: 16px;
        height: 16px;
        margin: 0 15px 0 5px; }
      .menu-item .status {
        margin: 0 15px 0 5px; } }

.user-menu-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: auto;
  display: flex;
  padding: 6px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0; }
  .user-menu-container .user-menu-body {
    width: 95%;
    padding-bottom: 6px;
    position: relative;
    overflow: hidden;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#484c70), to(#65698f));
    background-image: linear-gradient(to top, #484c70, #65698f);
    border-radius: 25px;
    box-shadow: 0 5px 15px 0 rgba(39, 41, 74, 0.7);
    height: 95%; }
    .user-menu-container .user-menu-body .user-menu-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      overflow: hidden;
      padding: 8px;
      font-size: 18px;
      justify-content: space-between; }
      .user-menu-container .user-menu-body .user-menu-header .user-menu-name {
        display: flex;
        z-index: 2;
        width: 50%;
        justify-content: flex-start;
        align-items: center; }
        .user-menu-container .user-menu-body .user-menu-header .user-menu-name .name {
          margin-left: 5px;
          font-size: 16px; }
      .user-menu-container .user-menu-body .user-menu-header .user-menu-close {
        z-index: 1; }
      .user-menu-container .user-menu-body .user-menu-header img {
        width: 41px;
        height: 41px; }
    .user-menu-container .user-menu-body .user-menu-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;
      padding: 0 13px; }
      .user-menu-container .user-menu-body .user-menu-items a {
        text-decoration: none; }
      .user-menu-container .user-menu-body .user-menu-items .items-header {
        width: 100%;
        text-align: left; }
    .user-menu-container .user-menu-body .user-menu-select {
      margin: 10px 5px; }

.user-menu-enter {
  -webkit-transform: translateY(-600px);
          transform: translateY(-600px); }
  .user-menu-enter .user-menu-icon {
    fill: #ced0ea; }

.user-menu-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 200ms ease, opacity 300ms ease;
  transition: all 200ms ease, opacity 300ms ease; }
  .user-menu-enter-active .user-menu-icon {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    fill: #5f638b; }

.user-menu-exit {
  opacity: 1;
  width: 100%; }

.user-menu-exit-active {
  -webkit-transform: translateY(-600px);
          transform: translateY(-600px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }

.settings-panel {
  border: 1px dotted #8b8fbb;
  border-radius: 90px;
  padding: 13px 20px;
  max-height: 107px;
  box-sizing: border-box; }

.header + .settings-panel {
  margin-top: 14px; }

.settings-panel + .total-amounts {
  margin-top: 14px; }

.settings-panel-title {
  position: relative;
  padding: 10px 3px 3px;
  color: #b1b3d9;
  cursor: pointer; }
  .settings-panel-title:after {
    content: '';
    position: absolute;
    top: 17px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #a7aad1 transparent transparent transparent; }
  .settings-panel-title.active:after {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }

.settings-panel-current {
  padding-left: 3px;
  padding-right: 3px; }
  .settings-panel-current div {
    display: inline-block;
    padding: 1px 8px;
    margin-right: 3px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 40px;
    background-color: #6c6e9f; }

.settings-panel-fields {
  display: flex;
  align-items: center; }

.settings-panel-field {
  padding: 0 20px;
  max-width: 260px;
  width: 20%;
  text-align: left;
  box-sizing: border-box; }
  .settings-panel-field.date-time {
    box-sizing: border-box; }
    .settings-panel-field.date-time img {
      margin-left: -24px; }
  .settings-panel-field.border-left {
    border-left: 1px dotted #8b8fbb; }

.settings-panel-credit {
  flex: 1 1;
  padding-left: 20px;
  text-align: left;
  box-sizing: border-box; }

.settings-panel-label {
  color: #b1b3d9;
  font-size: 16px;
  margin-bottom: 5px; }
  .settings-panel-label img {
    width: 19px;
    height: 19px; }
    .settings-panel-label img + span {
      margin-left: 6px; }
  @media (max-width: 1399px) {
    .settings-panel-label {
      font-size: 14px; }
      .settings-panel-label img {
        width: 15px;
        height: 15px; } }
  @media (max-width: 1199px) {
    .settings-panel-label {
      font-size: 12px; } }
  @media (max-width: 349px) {
    .settings-panel-label {
      font-size: 11px; }
      .settings-panel-label img {
        width: 12px;
        height: 12px; } }

.credit-settings {
  display: flex;
  align-items: center; }
  .credit-settings .inline-button-container + .inline-button-container {
    margin-left: 0.521vw; }

.credit-value {
  margin-right: 1.979vw; }
  @media (max-width: 1024px) {
    .credit-value {
      margin-right: 0; } }
  .credit-value .label {
    font-size: 0.9375vw;
    font-weight: 700;
    line-height: 1.333;
    color: #a7aad1; }
  .credit-value .value {
    font-size: 1.09375vw;
    font-weight: 700;
    line-height: 1.571;
    color: #fdc66b; }

.settings-panel.mobile {
  position: relative;
  max-height: none;
  padding: 2px 12px 14px;
  border-radius: 20px;
  text-align: left; }
  @media (max-width: 369px) {
    .settings-panel.mobile {
      padding-left: 7px;
      padding-right: 7px; } }
  .settings-panel.mobile .settings-panel-fields {
    flex-wrap: wrap;
    align-items: flex-start; }
  .settings-panel.mobile .settings-panel-field {
    width: 25%;
    padding: 0 5px 0 15px;
    margin-top: 10px;
    max-width: none; }
    .settings-panel.mobile .settings-panel-field.date-time {
      width: 50%; }
    @media (max-width: 799px) {
      .settings-panel.mobile .settings-panel-field {
        width: 50%; } }
    @media (max-width: 579px) {
      .settings-panel.mobile .settings-panel-field {
        width: 100%; } }
  .settings-panel.mobile .settings-panel-label img {
    margin-left: -15px; }
    .settings-panel.mobile .settings-panel-label img + span {
      margin-left: 3px; }
  .settings-panel.mobile .settings-panel-label * {
    vertical-align: middle; }
  .settings-panel.mobile .settings-panel-content {
    max-width: 280px; }
    @media (max-width: 579px) {
      .settings-panel.mobile .settings-panel-content {
        max-width: none; } }
  .settings-panel.mobile .rdtPicker {
    left: -20px; }
  @media (max-width: 519px) {
    .settings-panel.mobile .rdt {
      position: static; }
    .settings-panel.mobile .rdtPicker {
      top: -2px;
      left: -1px;
      right: -1px;
      width: auto; } }
  @media (min-width: 370px) and (max-width: 519px) {
    .settings-panel.mobile .rdtDays {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto; }
      .settings-panel.mobile .rdtDays table {
        border-spacing: 15px 0; } }
  @media (max-width: 349px) {
    .settings-panel.mobile .rdt input {
      font-size: 14px; } }
  .settings-panel.mobile .credit-settings {
    margin-top: 15px; }
    .settings-panel.mobile .credit-settings .button.featured {
      position: relative;
      width: 80px;
      height: 54px;
      padding: 0;
      font-size: 0; }
      .settings-panel.mobile .credit-settings .button.featured:before, .settings-panel.mobile .credit-settings .button.featured:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #69d7b3; }
      .settings-panel.mobile .credit-settings .button.featured:before {
        width: 20px;
        height: 4px; }
      .settings-panel.mobile .credit-settings .button.featured:after {
        width: 4px;
        height: 20px; }
      .settings-panel.mobile .credit-settings .button.featured.alt:before {
        width: 12px;
        height: 5px;
        background-color: #f57fc2; }
      .settings-panel.mobile .credit-settings .button.featured.alt:after {
        content: none; }
    .settings-panel.mobile .credit-settings .inline-button-container + .inline-button-container {
      margin-left: 8px; }
  .settings-panel.mobile .credit-value {
    margin-right: 36px; }
    @media (max-width: 399px) {
      .settings-panel.mobile .credit-value {
        margin-right: auto; } }
    .settings-panel.mobile .credit-value .label {
      color: #b1b3d9;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 1px; }
      .settings-panel.mobile .credit-value .label img {
        margin-right: 3px;
        margin-left: -18px; }
    .settings-panel.mobile .credit-value .value {
      font-size: 16px; }

.admin-amounts {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -12px; }

.admin-amount {
  width: 33.3333%;
  padding: 1.5625vw 1.927vw;
  border-radius: 80px;
  margin: 0 12px;
  font-size: 1.5625vw;
  line-height: 1.37;
  font-weight: 700;
  text-align: left;
  word-break: break-all; }
  @media (min-width: 700px) {
    .admin-amount br {
      display: none; } }
  .admin-amount.in {
    background: -webkit-gradient(linear, left top, left bottom, from(#60d1ab), to(#4ca88a));
    background: linear-gradient(180deg, #60d1ab 0%, #4ca88a 100%); }
  .admin-amount.out {
    background: -webkit-gradient(linear, left top, left bottom, from(#f275b8), to(#bf5994));
    background: linear-gradient(180deg, #f275b8 0%, #bf5994 100%); }
  .admin-amount.netto {
    background: -webkit-gradient(linear, left top, left bottom, from(#666996), to(#53577e));
    background: linear-gradient(180deg, #666996 0%, #53577e 100%); }

.app-container.mobile .admin-amounts {
  margin-right: -6px;
  margin-left: -6px; }

.app-container.mobile .admin-amount {
  padding: 18px 20px;
  font-size: 16px;
  border-radius: 18px;
  margin-right: 6px;
  margin-left: 6px; }
  @media (max-width: 539px) {
    .app-container.mobile .admin-amount {
      padding-left: 12px;
      padding-right: 12px; } }
  @media (max-width: 479px) {
    .app-container.mobile .admin-amount {
      font-size: 12px; } }

.preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #494d71;
  display: flex;
  align-items: center;
  justify-content: center; }

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both; }

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both; }
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
            animation: sk-chase-dot-before 2s infinite ease-in-out both; }
  .sk-chase-dot:nth-child(1) {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }
  .sk-chase-dot:nth-child(3) {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }
  .sk-chase-dot:nth-child(3):before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; }

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-family: "Open Sans";
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
       user-select: none;
  background: #27294a;
  height: 100%; }

label {
  margin: 5px 0;
  color: white; }

#root {
  height: 100%; }

.bg {
  background: #494d71;
  width: 100%;
  height: 100vh; }

.container {
  position: relative;
  background: #27294a url(../../static/media/bg.f63a0368.png);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1880px;
  margin: 0 auto; }

.content-container {
  width: 100%;
  height: 0;
  flex: 1 1;
  z-index: 1;
  display: flex;
  position: relative;
  padding: 20px 0 10px 0;
  flex-direction: row;
  box-sizing: border-box; }
  .content-container .right,
  .content-container .left,
  .content-container .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    align-items: center; }
    .content-container .right .button-group,
    .content-container .left .button-group,
    .content-container .content .button-group {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
  .content-container .content {
    width: 100%; }
  .content-container .left {
    padding-right: 30px; }
    .content-container .left::after {
      content: '';
      height: 100vh;
      width: 1px;
      position: absolute;
      right: 0;
      top: -19px;
      border-left: 1px dotted rgba(0, 0, 0, 0.15); }
  .content-container .right {
    padding-left: 30px; }

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 30px 10px;
  box-sizing: border-box;
  background-color: #494d71;
  flex-direction: column; }
  .app-container.mobile {
    padding: 10px;
    overflow-y: auto; }
    .app-container.mobile .content-container {
      height: auto;
      padding-top: 12px; }
  .app-container.admin .content-container {
    flex-direction: column; }

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px; }
  .logo-container .content-logo {
    width: 45px;
    height: 45px;
    background-color: #e55895;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem; }
    .logo-container .content-logo img {
      width: 35px;
      height: 35px; }
  .logo-container span {
    padding-right: 20px;
    line-height: 40px;
    font-weight: bold;
    color: white;
    border-right: 1px dotted #5f638b;
    margin: 0 13px;
    font-size: 1rem; }

.no-suggestions {
  color: #999;
  padding: 0.5rem; }

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute; }
  .suggestions li {
    padding: 0.5rem; }
    .suggestions li:not(:last-of-type) {
      border-bottom: 1px solid #999; }

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700; }

.icon-arrow {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-top: 3px solid #9da0c7;
  border-right: 3px solid #9da0c7; }
  .icon-arrow.down {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    margin: 3px 6px 6px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .icon-arrow.up {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    margin: 12px 6px 6px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.placeholder-option {
  color: #57c7a1;
  margin-right: 10px; }

