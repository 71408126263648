// ========================== History ==========================

.table-history-sections {
  display: flex;

  tbody {
    width: 100%;
  }

  tr {
    display: flex;
  }

  td {
    width: 100%;
  }
}

.history-sections {
  width: 100%;
  overflow-y: scroll;
  flex: 1 1 0;
  height: 0;

  &::-webkit-scrollbar {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: $font_color;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 4px;
    background-color: $input_bg;
  }
}

.history-container {
  padding-right: 10px;

  @media (max-width: 399px) {
    padding-right: 0;
  }
}

.history-date {
  width: 24%;
  max-width: 90px;
  min-width: 55px;
  text-align: left;
}

.history-time {
  width: 13%;
  max-width: 50px;
  text-align: left;
}

.history-type {
  width: 8%;

  .button-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;

    @media (max-width: 399px) {
      width: 10px;
      height: 10px;
    }

    &.load_funds {
      fill: $action_color_green;
    }

    &.unload_funds {
      fill: $action_color_pink;
    }
  }
}

.history-title {
  color: #2c2e55;
  border-bottom: 1px solid #27294a;
  margin-bottom: 10px;
  padding: 5px 20px;
  display: flex;
}

.history-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 10px;
  padding: 5px 20px;

  &:nth-child(even) {
    background-color: rgba(50, 53, 88, 0.5);
    border-radius: 5rem;
  }
}

.history-desc {
  width: 35%;
  text-align: left;
  margin-left: 4%;
}

.history-value {
  width: 26%;
  min-width: 65px;
  text-align: right;

  &.load_funds {
    color: $action_color_green;
  }

  &.unload_funds {
    color: $action_color_pink;
  }

  &.inc_cashier_credit {
    color: $action_color_green;
  }

  &.dec_cashier_credit {
    color: $action_color_pink;
  }
}

.history-value-credit {
  color: #fdc66b;
  min-width: 74px;
}

// History Alt

.history-alt {
  width: 100%;
  height: 45vh;
  position: relative;
  margin-top: 20px;

  .history-container {
    height: 100%;

    .history-item {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
      padding: 0.26vw 1.04vw;
      font-size: 1.25vw;
      line-height: 2.6vw;

      &:nth-child(even) {
        background-color: rgba(50, 53, 88, 0.5);
        border-radius: 5rem;
      }

      .history-date {
        text-align: left;
        flex: 0 0 11ex;
      }

      .history-time {
        text-align: left;
        flex: 0 0 5ex;
      }

      .history-type {
        flex: 0 1 200px; // 110 + 62+ 28

        .button-icon {
          width: 28px;
          height: 28px;
          padding: 0 62px 0 110px;

          &.load_funds {
            fill: $action_color_green;
          }

          &.unload_funds {
            fill: $action_color_pink;
          }
        }
      }

      .history-desc {
        text-align: left;
        flex: 1 1 12ex;
      }

      .history-value {
        flex: 1 1 8ex;
        text-align: right;

        &.load_funds {
          color: $action_color_green;
        }

        &.unload_funds {
          color: $action_color_pink;
        }
      }

      .history-clBallance {
        flex: 1 1 6ex;
        text-align: right;
        color: #fdc66b;
      }
    }
  }

  .history-sections::-webkit-scrollbar {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: $font_color;
  }

  .history-sections::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 4px;
    background-color: $input_bg;
  }

  &.history-credits {
    .history-container .history-item .history-clBallance {
      flex: 0 0 18ex;
    }
  }
}

// History Mobile

.app-container.mobile {
  .history-title {
    font-size: 12px;
  }

  .history-sections {
    min-height: 33vh;
    max-height: 50vh;

    @media (max-height: 399px) and (min-aspect-ratio: 13/9) {
      max-height: 75vh;
    }

    .history-item {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;

      @media (max-width: 479px) {
        padding: 7px 8px;
      }

      @media (max-width: 549px) {
        font-size: 10px;
      }
    }
  }
}
