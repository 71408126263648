// ========================== Admin Amounts ==========================

.admin-amounts {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -12px;
}

.admin-amount {
  width: 33.3333%;
  padding: 1.5625vw 1.927vw;
  border-radius: 80px;
  margin: 0 12px;
  font-size: 1.5625vw;
  line-height: 1.37;
  font-weight: 700;
  text-align: left;
  word-break: break-all;

  @media (min-width: 700px) {
    br {
      display: none;
    }
  }

  &.in {
    background: linear-gradient(180deg, #60d1ab 0%, #4ca88a 100%);
  }

  &.out {
    background: linear-gradient(180deg, #f275b8 0%, #bf5994 100%);
  }

  &.netto {
    background: linear-gradient(180deg, #666996 0%, #53577e 100%);
  }
}

// Mobile

.app-container.mobile {
  .admin-amounts {
    margin-right: -6px;
    margin-left: -6px;
  }

  .admin-amount {
    padding: 18px 20px;
    font-size: 16px;
    border-radius: 18px;
    margin-right: 6px;
    margin-left: 6px;

    @media (max-width: 539px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    @media (max-width: 479px) {
      font-size: 12px;
    }
  }
}
