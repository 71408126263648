$bg_color: #27294a;
$action_color_green: #58caa3;
$action_color_pink: #e55895;
$input_active_bg: #27294a;
$input_bg: #323558;
$white: #ffffff;
$hint_color: #9da0c7;
$input_font_color: #5f638b;
$font_family: 'Open Sans';
$font_color: #494d71;
$modal_gradient: linear-gradient(to top, #484c70, #65698f);
