// ========================== Menu ==========================

.menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 100%;
  max-width: 275px;
  max-height: 275px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 25px;
  justify-content: flex-start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: #34375c;

  &.load_funds,
  &.unload_funds {
    transition: all 300ms ease;

    &.disabled {
      filter: brightness(70%);
      cursor: inherit;

      .menu-button-key,
      .menu-button-name,
      .button-icon {
        opacity: 0.4;
      }

      &:active {
        box-shadow: none;
        outline: inherit;
        border: inherit;

        .menu-button-key {
          color: #34375c;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
        }

        .menu-button-name {
          color: #ffffff;
        }

        .button-icon {
          fill: #34375c;
        }
      }
    }

    &:active {
      transition: all 300ms ease;
      box-shadow: 0 5px 25px 0 #27294a;
      outline: none !important;
      background-color: $input_bg;

      .menu-button-key {
        border-bottom: none;
        color: $font_color;
      }

      .button-icon {
        width: 60px;
        cursor: pointer;
        height: 70px;
      }
    }
  }

  &.load_funds {
    background-color: $action_color_green;

    &.disabled:active {
      background-color: $action_color_green;
    }

    &:active {
      border: 2px solid $action_color_green;
      margin: -2px;

      .button-icon {
        fill: $action_color_green;
      }
    }
  }

  &.unload_funds {
    background-color: $action_color_pink;

    &.disabled:active {
      background-color: $action_color_pink;
    }

    &:active {
      border: 2px solid $action_color_pink;
      margin: -2px;

      .button-icon {
        fill: $action_color_pink;
      }
    }
  }

  .menu-button-key {
    width: 100%;
    font-size: 3vh;
    line-height: 140%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  }

  .menu-button-name {
    color: #ffffff;
    font-size: 3vh;
    margin-bottom: 14px;
  }

  .button-icon {
    margin: 6px 0 10px 0;
    width: 60px;
    height: 70px;
    fill: #34375c;
  }
}

// Mobile

.app-container.mobile {
  .menu-button {
    justify-content: space-evenly;

    .menu-button-key {
      display: none;
    }

    .button-icon {
      min-width: 35px;
      min-height: 40px;
      margin-top: 25px;
    }

    @media (max-width: 479px) {
      height: 110px;
      width: 31%;

      .button-icon {
        width: 50px;
        height: 50px;
        margin-top: 17px;
      }
    }
  }

  .menu-button-name {
    font-size: 16px;

    @media (max-width: 479px) {
      font-size: 14px;
    }
  }
}
