@import 'variables';
@import 'normalize';
@import 'fonts';
@import 'components/react-datetime';
@import 'components/user-login';
@import 'components/buttons';
@import 'components/inputs';
@import 'components/header';
@import 'components/menu';
@import 'components/history';
@import 'components/modal';
@import 'components/user-menu';
@import 'components/settings-panel';
@import 'components/admin-amounts';
@import 'components/preloader';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-family: $font_family;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #27294a;
  height: 100%;
}

label {
  margin: 5px 0;
  color: white;
}

#root {
  height: 100%;
}

.bg {
  background: $font_color;
  width: 100%;
  height: 100vh;
}

.container {
  position: relative;
  background: $bg_color url('../media/bg/bg.png');
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1880px;
  margin: 0 auto;
}

.content-container {
  width: 100%;
  height: 0;
  flex: 1;
  z-index: 1;
  display: flex;
  position: relative;
  padding: 20px 0 10px 0;
  flex-direction: row;
  box-sizing: border-box;

  .right,
  .left,
  .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    align-items: center;

    .button-group {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .content {
    width: 100%;
  }

  .left {
    padding-right: 30px;

    &::after {
      content: '';
      height: 100vh;
      width: 1px;
      position: absolute;
      right: 0;
      top: -19px;
      border-left: 1px dotted rgba(0, 0, 0, 0.15);
    }
  }

  .right {
    padding-left: 30px;
  }
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 30px 10px;
  box-sizing: border-box;
  background-color: #494d71;
  flex-direction: column;

  &.mobile {
    padding: 10px;
    overflow-y: auto;

    .content-container {
      height: auto;
      padding-top: 12px;
    }
  }

  &.admin {
    .content-container {
      flex-direction: column;
    }
  }
}

// Logo

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  .content-logo {
    width: 45px;
    height: 45px;
    background-color: $action_color_pink;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;

    img {
      width: 35px;
      height: 35px;
    }
  }

  span {
    padding-right: 20px;
    line-height: 40px;
    font-weight: bold;
    color: white;
    border-right: 1px dotted #5f638b;
    margin: 0 13px;
    font-size: 1rem;
  }
}

// Suggestions

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;

  li {
    padding: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #999;
    }
  }
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.icon-arrow {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-top: 3px solid $hint_color;
  border-right: 3px solid $hint_color;

  &.down {
    transition: all 300ms ease;
    margin: 3px 6px 6px;
    transform: rotate(135deg);
  }

  &.up {
    transition: all 300ms ease;
    margin: 12px 6px 6px;
    transform: rotate(-45deg);
  }
}

.placeholder-option {
  color: #57c7a1;
  margin-right: 10px;
}
